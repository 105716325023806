import {
  graphQLClientWithHeader,
  graphQLClientReportWithHeader,
} from "../graphQL";
import {
  CREATE_BOM,
  CREATE_BOMS,
  CREATE_OR_UPDATE_BOMS,
  CREATE_MANUFACTURE_ORDER,
  CREATE_WORK_ORDER,
  DELETE_BOM,
  DELETE_WORK_ORDER,
  SET_STATUS_WORK_ORDER,
  UPDATE_BOM,
  UPDATE_MANUFACTURE_ORDER,
  UPDATE_WORK_ORDER,
} from "./mutations";
import {
  GET_ALL_BOM,
  GET_ALL_BOM_NAMES,
  GET_ALL_MANUFACTURE_ORDERS,
  GET_ALL_MANUFACTURE_ORDERS_CALENDAR,
  GET_ALL_MANUFACTURE_ORDER_IDS,
  GET_ALL_UNDER_QUALITY_ITEMS,
  GET_ALL_MANUFACTURE_ORDERS_NO_AGGRID,
  GET_ALL_WORK_ORDER,
  GET_BOM,
  GET_MANUFACTURE_ORDER,
  GET_WORK_ORDER,
} from "./queries";

class ManufactureService {
  async getAllBoms(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { bomsAggrid } = await graphQLClient.request(GET_ALL_BOM, {
      aggridInput,
    });
    return bomsAggrid;
  }
  async getAllBomsReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { bomsAggrid } = await graphQLClient.request(GET_ALL_BOM, {
      aggridInput,
    });
    return bomsAggrid;
  }

  async getAllBomNames() {
    const graphQLClient = graphQLClientWithHeader();
    const { boms } = await graphQLClient.request(GET_ALL_BOM_NAMES);
    return boms;
  }

  async getBomById(id) {
    const graphQLClient = graphQLClientWithHeader();
    const { bom } = await graphQLClient.request(GET_BOM, {
      uniqueInput: {
        id: parseInt(id),
      },
    });
    return bom;
  }

  async createBom(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { bomCreate } = await graphQLClient.request(CREATE_BOM, {
      createInput,
    });
    return bomCreate;
  }

  async createBoms(createManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { bomCreateMany } = await graphQLClient.request(
      CREATE_BOMS,
      createManyInput
    );
    return bomCreateMany;
  }

  async createOrUpdateBoms(upsertManyInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { bomUpsertMany } = await graphQLClient.request(
      CREATE_OR_UPDATE_BOMS,
      upsertManyInput
    );
    return bomUpsertMany;
  }

  async updateBom(id, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { bomUpdate } = await graphQLClient.request(UPDATE_BOM, {
      uniqueInput: {
        id: parseInt(id),
      },
      updateInput,
    });
    return bomUpdate;
  }

  async deleteBom(id) {
    const graphQLClient = graphQLClientWithHeader();
    await graphQLClient.request(DELETE_BOM, {
      uniqueInput: {
        id: parseInt(id),
      },
    });
    return;
  }

  //<=============== Work Order ===============>
  async getAllWorkOrders(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { WorkOrdersAggrid } = await graphQLClient.request(
      GET_ALL_WORK_ORDER,
      {
        aggridInput,
      }
    );
    return WorkOrdersAggrid;
  }

  async getAllWorkOrdersReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { WorkOrdersAggrid } = await graphQLClient.request(
      GET_ALL_WORK_ORDER,
      {
        aggridInput,
      }
    );
    return WorkOrdersAggrid;
  }

  async getWorkOrderById(id) {
    const graphQLClient = graphQLClientWithHeader();
    const { WorkOrder } = await graphQLClient.request(GET_WORK_ORDER, {
      uniqueInput: {
        id: parseInt(id),
      },
    });
    return WorkOrder;
  }

  async createWorkOrder(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { workOrderCreate } = await graphQLClient.request(CREATE_WORK_ORDER, {
      createInput,
    });
    return workOrderCreate;
  }

  async updateWorkOrder(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { workOrderUpdate } = await graphQLClient.request(
      UPDATE_WORK_ORDER,
      input
    );
    return workOrderUpdate;
  }

  async deleteWorkOrder(id) {
    const graphQLClient = graphQLClientWithHeader();
    const { workOrderDelete } = await graphQLClient.request(DELETE_WORK_ORDER, {
      uniqueInput: {
        id: parseInt(id),
      },
    });
    return workOrderDelete;
  }

  async changeStatusWorkOrder(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { workOrderSetStatus } = await graphQLClient.request(
      SET_STATUS_WORK_ORDER,
      input
    );
    return workOrderSetStatus;
  }
  //<==========================================>
  async getAllManufactureOrderIds(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrdersIds } = await graphQLClient.request(
      GET_ALL_MANUFACTURE_ORDER_IDS,
      {
        aggridInput,
      }
    );
    return manufactureOrdersIds;
  }

  async getAllManufactureOrders(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrdersAggrid } = await graphQLClient.request(
      GET_ALL_MANUFACTURE_ORDERS,
      {
        aggridInput,
      }
    );
    return manufactureOrdersAggrid;
  }

  async getAllManufactureOrdersCalendar(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrdersCalendarAggrid } = await graphQLClient.request(
      GET_ALL_MANUFACTURE_ORDERS_CALENDAR,
      {
        aggridInput,
      }
    );
    return manufactureOrdersCalendarAggrid;
  }

  async getAllManufactureOrdersReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { manufactureOrdersAggrid } = await graphQLClient.request(
      GET_ALL_MANUFACTURE_ORDERS,
      {
        aggridInput,
      }
    );
    return manufactureOrdersAggrid;
  }

  async getAllManufactureOrdersWithoutAgGrid() {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrders } = await graphQLClient.request(
      GET_ALL_MANUFACTURE_ORDERS_NO_AGGRID
    );
    return manufactureOrders;
  }

  async getManufactureOrderById(document_id) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrder } = await graphQLClient.request(
      GET_MANUFACTURE_ORDER,
      {
        uniqueInput: {
          document_id,
        },
      }
    );
    return manufactureOrder;
  }

  async createManufactureOrder(createInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrderCreate } = await graphQLClient.request(
      CREATE_MANUFACTURE_ORDER,
      {
        createInput,
      }
    );
    return manufactureOrderCreate;
  }

  async updateManufactureOrder(document_id, updateInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrderUpdate } = await graphQLClient.request(
      UPDATE_MANUFACTURE_ORDER,
      {
        uniqueInput: {
          document_id,
        },
        updateInput,
      }
    );
    return manufactureOrderUpdate;
  }

  async getAllUnderqualityItems(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { manufactureOrderUnderQualityItemsAggrid } =
      await graphQLClient.request(GET_ALL_UNDER_QUALITY_ITEMS, {
        aggridInput,
      });
    return manufactureOrderUnderQualityItemsAggrid;
  }

  async getAllUnderqualityItemsReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { manufactureOrderUnderQualityItemsAggrid } =
      await graphQLClient.request(GET_ALL_UNDER_QUALITY_ITEMS, {
        aggridInput,
      });
    return manufactureOrderUnderQualityItemsAggrid;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ManufactureService();
