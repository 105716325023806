import {
  graphQLClientWithHeader,
  graphQLClientReportWithHeader,
} from "../graphQL";
import {
  CANCEL_QUOTATION,
  CREATE_CREDIT_NOTE,
  CREATE_DEPOSIT_INVOICE,
  CREATE_QUOTATION,
  CREATE_QUOTATIONS,
  CREATE_SALES_INVOICE,
  CREATE_SALES_ORDER,
  CREATE_SALES_RETURN,
  DOCUMENT_APPROVE,
  DOCUMENT_DECLINE,
  DOCUMENT_NEXT_STATUS,
  DOCUMENT_CANCEL,
  UPDATE_CREDIT_NOTE,
  UPDATE_DEPOSIT_INVOICE,
  UPDATE_QUOTATION,
  UPDATE_SALES_INVOICE,
  UPDATE_SALES_ORDER,
  UPDATE_SALES_RETURN,
} from "./mutation";
import {
  GET_ALL_CREDIT_NOTES,
  GET_ALL_DEPOSIT_INVOICES,
  GET_ALL_QUOTATIONS,
  GET_ALL_SALES_INVOICES,
  GET_ALL_SALES_ORDERS,
  GET_ALL_SALES_ORDER_IDS,
  GET_ALL_SALES_RETURNS,
  GET_ALL_SALES_RETURN_IDS,
  GET_APPROVAL_PROGRESS,
  GET_APPROVAL_TEMPLATES,
  GET_CREDIT_NOTE,
  GET_CREDIT_NOTE_ID,
  GET_DEPOSIT_INVOICE,
  GET_DEPOSIT_INVOICE_ID,
  GET_QUOTATION,
  GET_QUOTATION_ID,
  GET_QUOTATION_PRICE_LIST,
  GET_SALES_INVOICE,
  GET_SALES_INVOICE_ID,
  GET_SALES_ORDER,
  GET_SALES_ORDER_ID,
  GET_SALES_ORDER_LIST,
  GET_SALES_ORDER_NO_AG_GRID,
  GET_SALES_ORDER_LOGISTIC_REPORT,
  GET_SALES_REPORT_BY_CUSTOMER,
  GET_SALES_REPORT_BY_PRODUCT,
  GET_SALES_RETURN,
  GET_SALES_RETURN_ID,
  GET_SALES_RETURN_NO_AG_GRID,
  GET_ALL_NEW_SALES_ORDER,
} from "./queries";

class SalesService {
  //next status
  async postNextStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentNextStatus } = await graphQLClient.request(
      DOCUMENT_NEXT_STATUS,
      {
        documentInput,
      }
    );
    return documentNextStatus;
  }

  //approve
  async postApproveStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentApprove } = await graphQLClient.request(DOCUMENT_APPROVE, {
      documentInput,
    });
    return documentApprove;
  }

  //decline
  async postDeclineStatus(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentDecline } = await graphQLClient.request(DOCUMENT_DECLINE, {
      documentInput,
    });
    return documentDecline;
  }

  //cancel
  async cancelDocument(documentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { documentCancel } = await graphQLClient.request(DOCUMENT_CANCEL, {
      documentInput,
    });
    return documentCancel;
  }

  async getApprovalTemplatesByDocumentType(documentType) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalTemplatesByDocumentType } = await graphQLClient.request(
      GET_APPROVAL_TEMPLATES,
      { documentType }
    );
    return approvalTemplatesByDocumentType;
  }

  async getApprovalProgress(referenceDocumentInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { approvalProgressByDocument } = await graphQLClient.request(
      GET_APPROVAL_PROGRESS,
      {
        referenceDocumentInput,
      }
    );
    return approvalProgressByDocument;
  }

  //quotation
  async getQuotationId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { quotation } = await graphQLClient.request(GET_QUOTATION_ID, {
      uniqueInput,
    });
    return quotation;
  }

  async getAllQuotations(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { quotationsAggrid } = await graphQLClient.request(
      GET_ALL_QUOTATIONS,
      {
        aggridInput,
      }
    );
    return quotationsAggrid;
  }

  async getAllQuotationsReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { quotationsAggrid } = await graphQLClient.request(
      GET_ALL_QUOTATIONS,
      {
        aggridInput,
      }
    );
    return quotationsAggrid;
  }

  async getQuotationById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { quotation } = await graphQLClient.request(GET_QUOTATION, {
      uniqueInput,
    });
    return quotation;
  }

  async getQuotationPriceList(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { quotationsAggrid } = await graphQLClient.request(
      GET_QUOTATION_PRICE_LIST,
      {
        aggridInput,
      }
    );
    return quotationsAggrid;
  }

  async createQuotaion(createQuotationInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createQuotation } = await graphQLClient.request(CREATE_QUOTATION, {
      createQuotationInput,
    });
    return createQuotation;
  }

  async createQuotations(createQuotationsInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createQuotations } = await graphQLClient.request(
      CREATE_QUOTATIONS,
      {
        createQuotationsInput,
      }
    );
    return createQuotations;
  }

  async updateQuotation(updateQuotationInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateQuotation } = await graphQLClient.request(UPDATE_QUOTATION, {
      updateQuotationInput,
    });
    return updateQuotation;
  }

  async cancelQuotation(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { cancelQuotation } = await graphQLClient.request(CANCEL_QUOTATION, {
      uniqueInput,
    });
    return cancelQuotation;
  }
  //sales order
  async getSalesOrderId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrder } = await graphQLClient.request(GET_SALES_ORDER_ID, {
      uniqueInput,
    });
    return salesOrder;
  }
  async getAllSalesOrdersWithoutAggrid(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrders } = await graphQLClient.request(
      GET_SALES_ORDER_NO_AG_GRID,
      input
    );
    return salesOrders;
  }
  async getAllSalesOrdersForLogisticReport(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrders } = await graphQLClient.request(
      GET_SALES_ORDER_LOGISTIC_REPORT,
      input
    );
    return salesOrders;
  }

  // async getSalesOrderDocumentIdList() {

  // }

  async getAllSalesOrderIds(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrdersIds } = await graphQLClient.request(
      GET_ALL_SALES_ORDER_IDS,
      {
        aggridInput,
      }
    );
    return salesOrdersIds;
  }

  async getAllSalesOrders(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrdersAggrid } = await graphQLClient.request(
      GET_ALL_SALES_ORDERS,
      {
        aggridInput,
      }
    );
    return salesOrdersAggrid;
  }

  async getAllSalesOrdersReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { salesOrdersAggrid } = await graphQLClient.request(
      GET_ALL_SALES_ORDERS,
      {
        aggridInput,
      }
    );
    return salesOrdersAggrid;
  }

  async getSalesOrderById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrder } = await graphQLClient.request(GET_SALES_ORDER, {
      uniqueInput,
    });
    return salesOrder;
  }

  async createSalesOrder(createSalesOrderInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createSalesOrder } = await graphQLClient.request(
      CREATE_SALES_ORDER,
      {
        createSalesOrderInput,
      }
    );
    return createSalesOrder;
  }

  async updateSalesOrder(updateSalesOrderInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateSalesOrder } = await graphQLClient.request(
      UPDATE_SALES_ORDER,
      {
        updateSalesOrderInput,
      }
    );
    return updateSalesOrder;
  }
  //sales invoice
  async getSalesInvoiceId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesInvoice } = await graphQLClient.request(GET_SALES_INVOICE_ID, {
      uniqueInput,
    });
    return salesInvoice;
  }

  async getAllSalesInvoices(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesInvoicesAggrid } = await graphQLClient.request(
      GET_ALL_SALES_INVOICES,
      {
        aggridInput,
      }
    );
    return salesInvoicesAggrid;
  }

  async getAllSalesInvoicesReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { salesInvoicesAggrid } = await graphQLClient.request(
      GET_ALL_SALES_INVOICES,
      {
        aggridInput,
      }
    );
    return salesInvoicesAggrid;
  }

  async getOrderPriceList(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrdersForPriceListAggrid } = await graphQLClient.request(
      GET_SALES_ORDER_LIST,
      {
        aggridInput,
      }
    );
    return salesOrdersForPriceListAggrid;
  }

  async getSalesInvoiceById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesInvoice } = await graphQLClient.request(GET_SALES_INVOICE, {
      uniqueInput,
    });
    return salesInvoice;
  }

  async createSalesInvoice(createSalesInvoiceInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createSalesInvoice } = await graphQLClient.request(
      CREATE_SALES_INVOICE,
      {
        createSalesInvoiceInput,
      }
    );
    return createSalesInvoice;
  }

  async updateSalesInvoice(updateSalesInvoiceInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateSalesInvoice } = await graphQLClient.request(
      UPDATE_SALES_INVOICE,
      {
        updateSalesInvoiceInput,
      }
    );
    return updateSalesInvoice;
  }

  // deposit invoice
  async getDepositInvoiceId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { depositInvoice } = await graphQLClient.request(
      GET_DEPOSIT_INVOICE_ID,
      {
        uniqueInput,
      }
    );
    return depositInvoice;
  }

  async getAllDepositInvoices(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { depositInvoicesAggrid } = await graphQLClient.request(
      GET_ALL_DEPOSIT_INVOICES,
      {
        aggridInput,
      }
    );
    return depositInvoicesAggrid;
  }

  async getAllDepositInvoicesReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { depositInvoicesAggrid } = await graphQLClient.request(
      GET_ALL_DEPOSIT_INVOICES,
      {
        aggridInput,
      }
    );
    return depositInvoicesAggrid;
  }

  async getDepositInvoiceById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { depositInvoice } = await graphQLClient.request(
      GET_DEPOSIT_INVOICE,
      {
        uniqueInput,
      }
    );
    return depositInvoice;
  }

  async createDepositInvoice(createDepositInvoiceInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createDepositInvoice } = await graphQLClient.request(
      CREATE_DEPOSIT_INVOICE,
      {
        createDepositInvoiceInput,
      }
    );
    return createDepositInvoice;
  }

  async updateDepositInvoice(updateDepositInvoiceInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateDepositInvoice } = await graphQLClient.request(
      UPDATE_DEPOSIT_INVOICE,
      {
        updateDepositInvoiceInput,
      }
    );
    return updateDepositInvoice;
  }

  // sales return
  async getSalesReturnId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesReturn } = await graphQLClient.request(GET_SALES_RETURN_ID, {
      uniqueInput,
    });
    return salesReturn;
  }

  async getAllSalesReturnWithoutAggrid(input) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesReturns } = await graphQLClient.request(
      GET_SALES_RETURN_NO_AG_GRID,
      input
    );
    return salesReturns;
  }

  async getAllSalesReturnIds(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesReturnsAggrid } = await graphQLClient.request(
      GET_ALL_SALES_RETURN_IDS,
      {
        aggridInput,
      }
    );
    return salesReturnsAggrid;
  }

  async getAllSalesReturns(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesReturnsAggrid } = await graphQLClient.request(
      GET_ALL_SALES_RETURNS,
      {
        aggridInput,
      }
    );
    return salesReturnsAggrid;
  }

  async getAllSalesReturnsReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { salesReturnsAggrid } = await graphQLClient.request(
      GET_ALL_SALES_RETURNS,
      {
        aggridInput,
      }
    );
    return salesReturnsAggrid;
  }

  async getSalesReturnById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesReturn } = await graphQLClient.request(GET_SALES_RETURN, {
      uniqueInput,
    });
    return salesReturn;
  }

  async createSalesReturn(createSalesReturnInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createSalesReturn } = await graphQLClient.request(
      CREATE_SALES_RETURN,
      {
        createSalesReturnInput,
      }
    );
    return createSalesReturn;
  }

  async updateSalesReturn(updateSalesReturnInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateSalesReturn } = await graphQLClient.request(
      UPDATE_SALES_RETURN,
      {
        updateSalesReturnInput,
      }
    );
    return updateSalesReturn;
  }

  // credit note
  async getCreditNoteId(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creditNote } = await graphQLClient.request(GET_CREDIT_NOTE_ID, {
      uniqueInput,
    });
    return creditNote;
  }

  async getAllCreditNotes(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creditNotesAggrid } = await graphQLClient.request(
      GET_ALL_CREDIT_NOTES,
      {
        aggridInput,
      }
    );
    return creditNotesAggrid;
  }

  async getAllCreditNotesReport(aggridInput) {
    const graphQLClient = graphQLClientReportWithHeader();
    const { creditNotesAggrid } = await graphQLClient.request(
      GET_ALL_CREDIT_NOTES,
      {
        aggridInput,
      }
    );
    return creditNotesAggrid;
  }

  async getCreditNoteById(uniqueInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { creditNote } = await graphQLClient.request(GET_CREDIT_NOTE, {
      uniqueInput,
    });
    return creditNote;
  }

  async createCreditNote(createCreditNoteInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { createCreditNote } = await graphQLClient.request(
      CREATE_CREDIT_NOTE,
      {
        createCreditNoteInput,
      }
    );
    return createCreditNote;
  }

  async updateCreditNote(updateCreditNoteInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { updateCreditNote } = await graphQLClient.request(
      UPDATE_CREDIT_NOTE,
      {
        updateCreditNoteInput,
      }
    );
    return updateCreditNote;
  }

  async getSalesReportByCustomer() {
    const graphQLClient = graphQLClientWithHeader();
    const { salesReportByCustomer } = await graphQLClient.request(
      GET_SALES_REPORT_BY_CUSTOMER
    );
    return salesReportByCustomer;
  }

  async getSalesReportByProduct() {
    const graphQLClient = graphQLClientWithHeader();
    const { salesReportByCustomerProduct } = await graphQLClient.request(
      GET_SALES_REPORT_BY_PRODUCT
    );
    return salesReportByCustomerProduct;
  }

  async getAllNewSalesOrderReport(aggridInput) {
    const graphQLClient = graphQLClientWithHeader();
    const { salesOrderReport } = await graphQLClient.request(
      GET_ALL_NEW_SALES_ORDER,
      {
        aggridInput,
      }
    );
    return salesOrderReport;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SalesService();
