import { gql } from "graphql-request";

export const GET_ALL_BOM = gql`
  query BomsAggrid($aggridInput: AnyAggridInput) {
    bomsAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        name
        description
        created_date
        start_date
        end_date
        status
        internal_remark
        is_main_bom
        item_document_id
        item_name
        mfg_qty
        stock_uom_document_id
        stock_uom {
          document_id
          id
          name
        }
        type
        ingredient_list {
          uid
          item_document_id
          item_name
          qty
          uom
          item_description
        }
        creator_document_id
        created_by {
          document_id
          email
          first_name
          last_name
          img_url
        }
        item {
          manufacture_minimum_qty
        }
      }
    }
  }
`;

export const GET_BOM = gql`
  query Bom($uniqueInput: BomUniqueInput!) {
    bom(uniqueInput: $uniqueInput) {
      id
      name
      description
      created_date
      start_date
      end_date
      status
      internal_remark
      is_main_bom
      item_document_id
      item_name
      mfg_qty
      stock_uom_document_id
      stock_uom {
        document_id
        id
        name
      }
      type
      ingredient_list {
        uid
        item_document_id
        item_name
        qty
        uom
        item_description
      }
      creator_document_id
      created_by {
        document_id
        email
        first_name
        last_name
        img_url
      }
      item {
        manufacture_minimum_qty
      }
    }
  }
`;

export const GET_ALL_BOM_NAMES = gql`
  query Boms {
    boms {
      name
    }
  }
`;

export const GET_ALL_WORK_ORDER = gql`
  query WorkOrdersAggrid($aggridInput: AnyAggridInput) {
    WorkOrdersAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        name
        status
        external_ref_id
        plant
        machine
        start_date_time
        duration
        duration_unit
        description
        manufacture_order_document_id
        manufacture_order {
          document_id
          group {
            id
            name
          }
        }
        created_date
        created_by {
          id
          document_id
          first_name
          last_name
          img_url
        }
        manufacture_order_document_id
        manufacture_order {
          group {
            name
          }
        }
      }
    }
  }
`;

export const GET_WORK_ORDER = gql`
  query WorkOrder($uniqueInput: WorkOrderUniqueInput!) {
    WorkOrder(uniqueInput: $uniqueInput) {
      id
      name
      status
      external_ref_id
      plant
      machine
      start_date_time
      duration
      duration_unit
      description
      created_date
      manufacture_order_document_id
      manufacture_order {
        document_id
        group {
          id
          name
        }
      }
      created_by {
        id
        document_id
        first_name
        last_name
        img_url
      }
    }
  }
`;

export const GET_ALL_MANUFACTURE_ORDER_IDS = gql`
  query ManufactureOrdersIds($aggridInput: AnyAggridInput) {
    manufactureOrdersIds(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
      }
    }
  }
`;

export const GET_ALL_MANUFACTURE_ORDERS = gql`
  query ManufactureOrdersAggrid($aggridInput: AnyAggridInput) {
    manufactureOrdersAggrid(aggridInput: $aggridInput) {
      count
      results {
        id
        document_id
        created_date
        updated_date
        issue_date
        expect_date
        expect_product_date
        delivery_date
        external_ref_id
        employee_list {
          document_id
          first_name
          last_name
          email
          img_url
        }
        item_group_level_1_name
        internal_remark_id
        internal_remark
        customer_contact_document_id
        customer_contact_name
        customer_detail {
          contact_name_1
          contact_name_2
          document_id
        }
        item_document_id
        item_name
        item_remark
        item_description
        production_qty
        actual_production_qty
        uom
        bom_id
        bom_detail
        type
        group {
          id
          name
          entity
        }
        creator_document_id
        created_by {
          document_id
          first_name
          last_name
          img_url
          email
        }
        ingredient_list
        work_order_list {
          id
          name
          status
          external_ref_id
          plant
          machine
          start_date_time
          duration_unit
          duration
          description
        }
        goods_receive_qty
        under_quality_item_list
        target_status
        render_status
        reference_document_list {
          reference_document_id
          reference_document_type
        }
      }
    }
  }
`;

export const GET_ALL_MANUFACTURE_ORDERS_CALENDAR = gql`
  query ManufactureOrdersCalendarAggrid($aggridInput: AnyAggridInput) {
    manufactureOrdersCalendarAggrid(aggridInput: $aggridInput) {
      count
      results {
        document_id
        expect_date
        expect_product_date
        item_document_id
        item_name
        type
        production_qty
        uom
        group {
          id
          name
          entity
        }
        employee_list {
          document_id
          first_name
          last_name
          email
          img_url
        }
        render_status
      }
    }
  }
`;

export const GET_ALL_UNDER_QUALITY_ITEMS = gql`
  query ManufactureOrderUnderQualityItemsAggrid($aggridInput: AnyAggridInput) {
    manufactureOrderUnderQualityItemsAggrid(aggridInput: $aggridInput) {
      count
      results {
        document_id
        issue_date
        expect_product_date
        type
        internal_remark
        group {
          name
        }
        under_quality_item_list
      }
    }
  }
`;

export const GET_ALL_MANUFACTURE_ORDERS_NO_AGGRID = gql`
  query ManufactureOrders {
    manufactureOrders {
      id
      document_id
      item_document_id
      item_name
      item_remark
      item_description
      production_qty
      actual_production_qty
      uom
      ingredient_list
      goods_receive_qty
    }
  }
`;

export const GET_MANUFACTURE_ORDER = gql`
  query ManufactureOrder($uniqueInput: ManufactureOrderWhereUniqueInput!) {
    manufactureOrder(uniqueInput: $uniqueInput) {
      id
      document_id
      created_date
      issue_date
      expect_date
      expect_product_date
      delivery_date
      external_ref_id
      employee_list {
        document_id
        first_name
        last_name
        email
        img_url
      }
      internal_remark_id
      internal_remark
      customer_contact_document_id
      customer_contact_name
      customer_detail {
        contact_name_1
        contact_name_2
        document_id
      }
      item_document_id
      item_name
      item_remark
      item_description
      production_qty
      actual_production_qty
      uom
      bom_id
      bom_detail
      type
      group {
        id
        name
        entity
      }
      creator_document_id
      created_by {
        document_id
        first_name
        last_name
        img_url
        email
      }
      ingredient_list
      work_order_list {
        id
        indexing
        name
        status
        external_ref_id
        plant
        machine
        start_date_time
        duration_unit
        duration
        description
      }
      goods_receive_qty
      under_quality_item_list
      target_status
      render_status
      reference_document_list {
        reference_document_id
        reference_document_type
      }
    }
  }
`;
